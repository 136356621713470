import {
  ViewerSettings,
} from '@axisnow/readium-shared-js';

import {
  ISettingEntry,
  SettingName,
  SpreadMode,
  ViewSettings,
} from '@readium/navigator-web';

// tslint:disable:no-any

interface IR1ViewerSettingValueConverter {
  name: string;
  // tslint:disable-next-line:prefer-method-signature
  valueConverter: (val: any) => any;
}

interface IR1ViewerSettingReverseValueConverter {
  name: SettingName;
  // tslint:disable-next-line:prefer-method-signature
  valueConverter: (val: any) => any;
}

function genericValueConverter(value: any): any {
  return value;
}


function spreadModeToString(mode: SpreadMode): string | null {
  let val: string | null = null;
  switch (mode) {
    case SpreadMode.FitViewportAuto:
      val = 'auto';
      break;
    case SpreadMode.FitViewportSingleSpread:
      val = 'single';
      break;
    case SpreadMode.FitViewportDoubleSpread:
      val = 'double';
      break;
  }

  return val;
}

export class R1ViewSettingsAdapter {
  private readonly R1_SETTING_MAP: Map<
    string,
    IR1ViewerSettingValueConverter
  > = new Map([
    [
      SettingName.ColumnGap,
      { name: 'columnGap', valueConverter: genericValueConverter },
    ],
    [
      SettingName.FontSize,
      { name: 'fontSize', valueConverter: genericValueConverter },
    ],
    [
      SettingName.SpreadMode,
      { name: 'syntheticSpread', valueConverter: spreadModeToString },
    ],
    [
      SettingName.FontFamily,
      { name: 'fontSelection', valueConverter: genericValueConverter },
    ],
  ]);

  private readonly R1_SETTING_REVERSE_MAP: Map<
    string,
    IR1ViewerSettingReverseValueConverter
  > = new Map([
    [
      'columnGap',
      { name: SettingName.ColumnGap, valueConverter: genericValueConverter },
    ],
    [
      'fontSize',
      { name: SettingName.FontSize, valueConverter: genericValueConverter },
    ],
    [
      'fontSelection',
      { name: SettingName.FontFamily, valueConverter: genericValueConverter },
    ],
  ]);

  public rsjViewerSettings(vs: ViewSettings): any {
    const rjs: object = {};

    const settingEntries = vs.getAllSettings();
    for (const setting of settingEntries) {
      const converter = this.R1_SETTING_MAP.get(setting.name);
      if (converter) {
        const r1Name = converter.name;
        const r1Value = converter.valueConverter(setting.value);
        Object.defineProperty(rjs, r1Name, { value: r1Value });
      }
    }

    return new ViewerSettings(rjs);
  }

  public r2ViewSettings(rjsVs: any): ISettingEntry[] {
    const settingEntries: ISettingEntry[] = [];
    for (const [key, value] of Object.entries(rjsVs)) {
      const converter = this.R1_SETTING_REVERSE_MAP.get(key);
      if (converter) {
        const r2Name = converter.name;
        const r2Value = converter.valueConverter(value);

        settingEntries.push({ name: r2Name, value: r2Value });
      }
    }
    return settingEntries;
  }
}
