export class Fonts {
  private static _fonts: any = {};

  public static setFonts(fonts: any) {
    Fonts._fonts = fonts;
  }

  public static getFonts() {
    return Fonts._fonts;
  }
}
