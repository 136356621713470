import {
  Globals as Readium,
  PaginationChangedEventArgs,
  StyleCollection,
} from '@axisnow/readium-shared-js';
import { Link } from '@readium/shared-models/lib/models/publication/link';

import {
  CancellationToken,
  IContentView,
  IFrameLoader,
  ViewSettings,
  SelfResizeCallback,
} from '@readium/navigator-web';
import { getReadiumEventsRelayInstance } from '../readium-events-relay';
import { R1ViewSettingsAdapter } from './r1-view-settings-adapter';
import { Fonts } from '../fonts';

// tslint:disable:no-any

export class R1ContentView implements IContentView {

  protected iframeLoader: IFrameLoader;

  protected host: HTMLElement;

  protected contentViewImpl: any;

  protected rsjSpine: any;
  protected $iframe: any;
  protected rjsSpineItem: any;

  protected spineItem: Link;
  protected spineItemIndex: number;
  protected spineItemPgCount: number = 1;

  protected selfResizeCallbacks: SelfResizeCallback[] = [];

  protected vsAdapter: R1ViewSettingsAdapter = new R1ViewSettingsAdapter();

  protected rsjBookStyle: any;

  public constructor(iframeLoader: IFrameLoader, rsjSpine: any, rsjBookStyle: any) {
    this.iframeLoader = iframeLoader;
    this.rsjSpine = rsjSpine;
    this.rsjBookStyle = rsjBookStyle;
    this.onPaginationResize = this.onPaginationResize.bind(this);
  }

  public attachToHost(host: HTMLElement): void {
    this.host = host;
  }
  public render(): void {
    this.contentViewImpl.render();
  }

  public element(): HTMLElement {
    return <HTMLElement>this.contentViewImpl.element()[0];
  }

  public metaWidth(): number {
    return 0;
  }

  public metaHeight(): number {
    return 0;
  }

  public calculatedHeight(): number {
    return 0;
  }

  public spineItemPageCount(): number {
    const pageInfo = this.contentViewImpl.getPaginationInfo().openPages[0];
    return pageInfo.spineItemPageCount;
  }

  public getOffsetFromCfi(cfi: string): number {
    console.warn('R1 view does not support getOffsetFromCfi()');

    return 0;
  }

  public getOffsetFromElementId(cfi: string): number {
    console.warn('R1 view does not support getOffsetFromElementId()');

    return 0;
  }

  public getPageIndexOffsetFromCfi(cfi: string): number {
    return this.contentViewImpl.getPageIndexOffsetFromCfi(cfi);
  }

  public getPageIndexOffsetFromElementId(elementId: string): number {
    return this.contentViewImpl.getNavigator().getPageIndexDeltaForElementId(elementId);
  }

  public iframeWidth(): number {
    return this.contentViewImpl.getLoadedContentFrames()[0].$iframe[0].clientWidth;
  }

  public getCfi(offsetMain: number, offset2nd: number, backward: boolean): string {
    const navLogic = this.contentViewImpl.getNavigator();

    // might fail for scrollable view?
    if (backward) {
      return navLogic.getLastVisibleCfi({ top: offset2nd, left: -offsetMain });
    } else {
      return navLogic.getFirstVisibleCfi({ top: offset2nd, left: -offsetMain });
    }

  }

  public getStartCfi(): object {
    return this.contentViewImpl.getStartCfi();
  }

  public getEndCfi(): object {
    return this.contentViewImpl.getEndCfi();
  }

  public getRangeCfiFromDomRange(range: Range): any {
    return this.contentViewImpl.getRangeCfiFromDomRange(range);
  }

  public getDomRangeFromRangeCfi(rangeCfi: any, rangeCfi2: any, inclusive: boolean): any {
    return this.contentViewImpl.getDomRangeFromRangeCfi(rangeCfi, rangeCfi2, inclusive);
  }

  public getVisibleElements(selector: string, includeSpineItems: boolean): any {
    return this.contentViewImpl.getVisibleElements(selector, includeSpineItems);
  }

  public getElements(selector: string): any {
    return this.contentViewImpl.getElements(this.rjsSpineItem.idref, selector);
  }

  public getElementById(id: string): any {
    return this.contentViewImpl.getElementById(this.rjsSpineItem.idref, id);
  }

  public getElementByCfi(cfi: string): HTMLElement | null {
    const $element = this.contentViewImpl.getNavigator().getElementByCfi(cfi);
    if (!$element) {
      return null;
    }

    const node = $element[0];
    if (node.nodeType === Node.TEXT_NODE) {
      return node.parentElement;
    }
    return node;
  }

  public isElementVisible($ele: any, offsetMain: number, offset2nd: number): boolean {
    const navLogic = this.contentViewImpl.getNavigator();

    return navLogic.isElementVisible($ele, {
      top: offset2nd,
      left: -offsetMain,
    });
  }

  public getCfiFromElementId(elementId: string): string {
    throw new Error('Method not implemented.');
  }

  public getFragments(cfi: string): string[] {
    return [];
  }

  public getNearestCfiFromElement(element: any): any {
    const navLogic = this.contentViewImpl.getNavigator();
    const contentCFI = navLogic.getNearestCfiFromElement(element);
    const idref = this.rjsSpineItem.idref;

    return { idref, contentCFI };
  }

  public getPaginationInfo(): object {
    return {
      paginationInfo: this.contentViewImpl.getPaginationInfo(),
      initiator: this,
      spineItem: this.contentViewImpl.getLoadedSpineItems()[0],
      elementId: undefined,
    };
  }

  public async loadSpineItem(
    spineItem: Link,
    spineItemIndex: number,
    viewSettings: ViewSettings,
    token?: CancellationToken,
  ): Promise<void> {
    this.spineItem = spineItem;
    this.spineItemIndex = spineItemIndex;

    const readiumViewParams = {
      $viewport: this.host,
      spine: this.rsjSpine,
      userStyles: new StyleCollection(),
      bookStyles: this.rsjBookStyle,
      iframeLoader: this.iframeLoader,
      expandDocumentFullWidth: true,
    };

    const rsjVs = this.vsAdapter.rsjViewerSettings(viewSettings);

    const reader = {
      fonts: Fonts.getFonts(),
      viewerSettings: () => rsjVs,
      needsFixedLayoutScalerWorkAround: () => false,
    };

    return this.loadSpineItemContentViewImpl(readiumViewParams, reader, rsjVs, token);
  }

  public getSpineItem(): Link {
    return this.spineItem;
  }

  public spineItemLoadedPromise(token?: CancellationToken): Promise<void> {
    return this.paginationChangedPromise(token);
  }

  public unloadSpineItem(): void {
    this.contentViewImpl.off(Readium.InternalEvents.CURRENT_VIEW_PAGINATION_CHANGED,
                             this.onPaginationResize);
    this.contentViewImpl.detachResizeSensor();
    if (this.$iframe){
      getReadiumEventsRelayInstance().triggerContentDocumentUnloaded(this.$iframe, this.rjsSpineItem);
    } 
    getReadiumEventsRelayInstance().unregisterEvents(this.contentViewImpl);
  }

  public setViewSettings(viewSetting: ViewSettings): void {
    this.contentViewImpl.setViewSettings(this.vsAdapter.rsjViewerSettings(viewSetting));

    const pageInfo = this.contentViewImpl.getPaginationInfo().openPages[0];
    this.spineItemPgCount = pageInfo.spineItemPageCount;
  }

  public scale(scale: number): void {
    return;
  }

  public onResize(): void {
    this.contentViewImpl.onViewportResize();

    const pageInfo = this.contentViewImpl.getPaginationInfo().openPages[0];
    this.spineItemPgCount = pageInfo.spineItemPageCount;
  }

  public onSelfResize(callback: SelfResizeCallback): void {
    this.selfResizeCallbacks.push(callback);
  }

  public applyBookStyles(): void {
    this.contentViewImpl.applyBookStyles();
  }

  protected loadSpineItemContentViewImpl(
    params: any,
    reader: any,
    rsjViewerSettings: any,
    token?: CancellationToken,
  ): Promise<void> {
    // Should be provided in subclass
    return Promise.resolve();
  }

  protected handleDocumentContentLoaded(): void {
  }

  protected paginationChangedHanlder(
    paras: PaginationChangedEventArgs,
    handler: (paras: PaginationChangedEventArgs) => void,
    resolve: () => void,
    token?: CancellationToken,
  ): void {
    const pageInfo = paras.paginationInfo.openPages[0];
    if (pageInfo.spineItemIndex === this.spineItemIndex) {
      this.contentViewImpl.removeListener(
        Readium.InternalEvents.CURRENT_VIEW_PAGINATION_CHANGED,
        handler,
      );
      this.handleDocumentContentLoaded();
      this.spineItemPgCount = pageInfo.spineItemPageCount;
      console.log(`spine item ${this.spineItemIndex} loaded: ${this.spineItemPgCount} pages`);
      this.contentViewImpl.on(Readium.InternalEvents.CURRENT_VIEW_PAGINATION_CHANGED,
                              this.onPaginationResize);
      resolve();
    }
  }

  protected paginationChangedPromise(token?: CancellationToken): Promise<void> {
    return new Promise<void>((resolve: () => void) => {
      const handler = (paras: PaginationChangedEventArgs) => {
        this.paginationChangedHanlder(paras, handler, resolve, token);
      };
      this.contentViewImpl.on(Readium.InternalEvents.CURRENT_VIEW_PAGINATION_CHANGED, handler);
    });
  }

  protected onPaginationResize(paras: PaginationChangedEventArgs):void {
    const pageInfo = paras.paginationInfo.openPages[0];
    if (pageInfo.spineItemIndex !== this.spineItemIndex) {
      return;
    }
    if (this.spineItemPgCount === pageInfo.spineItemPageCount) {
      return;
    }

    this.spineItemPgCount = pageInfo.spineItemPageCount;

    for (const callback of this.selfResizeCallbacks) {
      callback(this.spineItemIndex);
    }
  }
}
