import {
  Globals as RsjGlobals,
  Helpers,
  InternalLinksSupport,
} from '@axisnow/readium-shared-js';
import {
    IContentView,
    Location,
    Navigator,
    Publication,
    Rendition,
    RenditionContext,
    SettingName,
    ScrollMode,
    SpreadMode,
    ZoomOptions,
    ViewSettings,
    IFrameLoader,
} from '@readium/navigator-web';
// tslint:disable-next-line:import-name
import $ from 'jquery';
import { R1ContentView } from './content-view/r1-content-view';
import { R1ContentViewFactory } from './content-view/r1-content-view-factory';
import { getReadiumEventsRelayInstance } from './readium-events-relay';
import { R1ViewSettingsAdapter } from './content-view/r1-view-settings-adapter';
import { PackageDocument } from './package-document';

/* tslint:disable:no-any */

type ListenerFn = (...args: any[]) => void;

enum ViewType {
  VIEW_TYPE_COLUMNIZED = 1,
  VIEW_TYPE_FIXED = 2,
  VIEW_TYPE_SCROLLED_DOC = 3,
  VIEW_TYPE_SCROLLED_CONTINUOUS = 4,
}

interface IZoomOption {
  style: string;
  scale: number;
}

interface IPageRequest {
  elementCfi: string;
  elementId: string;
  pageIndex: number;
  idref: string;
}

export class ReadiumReaderViewAdapter {
  private rsjPackageDoc: any;
  private rsjPackage: any;

  private publication: Publication;
  private rendCtx?: RenditionContext;

  private contentViewFactory: R1ContentViewFactory;

  private viewRoot: HTMLElement;
  private epubContainer?: HTMLElement;

  private viewportWidth: number = 0;
  private viewportHeight: number = 0;

  private iframeEventManager: IframeEventManager = new IframeEventManager();

  private defaultViewsettings: ViewSettings = new ViewSettings();
  private vsAdapter: R1ViewSettingsAdapter = new R1ViewSettingsAdapter();

  private scrollMode: ScrollMode = ScrollMode.None;
  private spreadMode: SpreadMode = SpreadMode.FitViewportAuto;

  private idrefToHrefMap: Map<string, string> = new Map<string, string>();
  private hrefToIdrefMap: Map<string, string> = new Map<string, string>();

  public constructor(viewRoot: HTMLElement, epubContainer?: HTMLElement) {
    this.viewRoot = viewRoot;
    this.epubContainer = epubContainer;

    if (this.epubContainer) {
      this.viewportWidth = this.epubContainer.clientWidth;
      this.viewportHeight = this.epubContainer.clientHeight;
    }

    this.initDefaultViewSettings();
  }

  private async openPublication(publication: Publication,
                                iframeLoader?: IFrameLoader,
                                packageDoc?: PackageDocument): Promise<void> {
    this.publication = publication;

    this.viewRoot.style.width = `${this.viewportWidth}px`;
    this.viewRoot.style.height = `${this.viewportHeight}px`;
    this.viewRoot.style.position = 'relative';

    this.contentViewFactory = new R1ContentViewFactory(this.publication, iframeLoader, packageDoc);
    if (!packageDoc) {
      await this.contentViewFactory.getReadiumPackageDocument().initPackageDom();
    }
    const rendition = new Rendition(
      this.publication,
      this.viewRoot,
      this.contentViewFactory,
    );
    this.rendCtx = new RenditionContext(rendition, this.contentViewFactory.getIframeLoader());

    const isVertical = this.scrollMode !== ScrollMode.None;

    rendition.setViewAsVertical(isVertical);

    let viewportSize = isVertical ? this.viewportHeight : this.viewportWidth;
    const viewportSize2 = isVertical ? this.viewportWidth : this.viewportHeight;

    // AXNG-116: round to even number to avoid fractional column width
    if (!isVertical && viewportSize % 2 !== 0) {
      viewportSize -= 1;
    }

    rendition.viewport.setViewportSize(viewportSize, viewportSize2);
    rendition.viewport.setPrefetchSize(Math.ceil(viewportSize * 0.1));

    rendition.updateViewSettings(this.defaultViewsettings.getAllSettings());
    rendition.setPageLayout({ spreadMode: this.spreadMode });

    rendition.render().then(() => {
      rendition.setNonSequentialIframeLoading(false);
    });
    rendition.viewport.setScrollMode(this.scrollMode);
    if (!this.isCurrentViewFixedLayout()) {
      this.viewRoot.style.overflow = 'hidden';
    }

    rendition.viewport.onVisiblePagesReady((cv: IContentView) => {
      getReadiumEventsRelayInstance().triggerContentDocumentLoadedEvents();

      const contentView = <R1ContentView>(cv);
      const rjsPageInfo = contentView.getPaginationInfo();
      this.replaceCanGoLeftRight((<any>rjsPageInfo).paginationInfo);
      getReadiumEventsRelayInstance().triggerPaginationChanged(rjsPageInfo);
    });

    rendition.viewport.onPrefetchReady(() => {
      getReadiumEventsRelayInstance().triggerPrefetchReady();
    });

    this.rsjPackageDoc = this.contentViewFactory.getReadiumPackageDocument();
    this.rsjPackage = this.contentViewFactory.getReadiumPackage();

    this.iframeEventManager.setInternalLinkSupport(new InternalLinksSupport(this));
    this.iframeEventManager.setViewerSetttings(() => {
      return this.viewerSettings()
    });

    if (this.epubContainer) {
      new ViewportResizer(
        this.epubContainer,
        this.rendCtx.rendition,
        this.rendCtx.navigator,
      );
    }
  }

  public async openPublicationFromURL(url: string,
                                      iframeLoader?: IFrameLoader,
                                      packageDoc?: PackageDocument): Promise<void> {
    return this.openPublication(await Publication.fromURL(url), iframeLoader, packageDoc);
  }

  public async openPublicationFromJSON(json: string,
                                       iframeLoader?:IFrameLoader,
                                       packageDoc?: PackageDocument): Promise<void> {
    const pub = await Publication.fromJSON(json);
    const metadata = JSON.parse(json).metadata;
    if (metadata && metadata.readingProgession) {
      pub.metadata.readingProgression = metadata.readingProgession;
    }
    return this.openPublication(pub, iframeLoader, packageDoc);
  }

  public setIdRefHrefMap(spine: any[]): void {
    for (const spineItem of spine) {
      this.idrefToHrefMap.set(spineItem.idref, spineItem.href);
      this.hrefToIdrefMap.set(spineItem.href, spineItem.idref);
    }
  }

  public async openPage(openPageRequest?: IPageRequest): Promise<void> {
    if (!this.rendCtx) {
      return;
    }

    if (openPageRequest && openPageRequest.idref) {
      if (openPageRequest.elementCfi) {
        await this.openSpineItemElementCfi(openPageRequest.idref, openPageRequest.elementCfi, self);
      } else if (openPageRequest.elementId) {
        await this.openSpineItemElementId(openPageRequest.idref, openPageRequest.elementId);
      } else {
        const pageIndex = openPageRequest.pageIndex ? openPageRequest.pageIndex : undefined;
        await this.openSpineItemPage(openPageRequest.idref, pageIndex);
      }
    } else {
      await this.rendCtx.navigator.gotoBegin();
    }

    getReadiumEventsRelayInstance().triggerContentDocumentLoadedEvents();

    const paginationInfo = this.getPaginationInfoInternal();
    getReadiumEventsRelayInstance().triggerPaginationChanged(paginationInfo);
    return Promise.resolve();
  }

  public getReadiumPackageDocument(): any {
    return this.rsjPackageDoc;
  }

  public getReadiumPackage(): any {
    return this.rsjPackage;
  }

  // tslint:disable-next-line:no-reserved-keywords
  public package(): any {
    return this.rsjPackage;
  }

  public spine(): any {
    return this.rsjPackage.spine;
  }

  public on(event: string, fn: ListenerFn, context?: any): void {
    getReadiumEventsRelayInstance().on(event, fn, context);
  }

  public off(
    event: string,
    fn?: ListenerFn,
    context?: any,
    once?: boolean,
  ): void {
    getReadiumEventsRelayInstance().off(event, fn, context, once);
  }

  public once(event: string, fn: ListenerFn, context?: any): void {
    getReadiumEventsRelayInstance().once(event, fn, context);
  }

  public emit(event: string, context?: any): void {
    getReadiumEventsRelayInstance().emit(event, context);
  }

  public getLoadedSpineItems(): any[] {
    if (!this.rendCtx) {
      return [];
    }

    const itemRange = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
    const ret: any[] = [];
    if (itemRange.length === 0) {
      return ret;
    }

    for (let i = itemRange[0]; i <= itemRange[1]; i = i + 1) {
      ret.push(this.rsjPackage.spine.items[i]);
    }

    return ret;
  }

  // tslint:disable-next-line:no-empty
  public handleViewportResize(): void {}

  public isCurrentViewFixedLayout(): boolean {
    return this.getCurrentViewType() === ViewType.VIEW_TYPE_FIXED;
  }

  public isMediaOverlayAvailable(): boolean {
    return false;
  }

  public setBookStyles(styles: any[]): void {
    if (!this.rendCtx) {
      return;
    }

    const bookStyles = this.contentViewFactory.getBookStyle();
    for (const style of styles) {
      if (style.declarations) {
        bookStyles.addStyle(style.selector, style.declarations);
      } else {
        bookStyles.removeStyle(style.selector);
      }
    }

    const itemRange = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
    if (itemRange.length === 0) {
      return;
    }

    for (let i = itemRange[0]; i <= itemRange[1]; i = i + 1) {
      const r1View = this.getContentViewFromSpineItemIndex(i);
      if (r1View) {
        r1View.applyBookStyles();
      }
    }
  }

  public getLoadedContentFrames(): object[] {
    if (!this.rendCtx) {
      return [];
    }

    const itemRange = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
    const ret: object[] = [];
    if (itemRange.length === 0) {
      return ret;
    }

    const iframes = this.viewRoot.querySelectorAll('iframe');
    const iframeMap = new Map<number, HTMLElement>();
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < iframes.length; i = i + 1) {
      const iframe = iframes[i];
      const viewDiv = this.getParentElement(iframe, 3);
      const sIndex = this.getSpineItemIndexFromId(viewDiv);
      iframeMap.set(sIndex, iframes[i]);
    }

    for (let i = itemRange[0]; i <= itemRange[1]; i = i + 1) {
      const iframe = iframeMap.get(i);
      if (iframe) {
        ret.push({
          spineItem: this.rsjPackage.spine.items[i],
          $iframe: $(iframe),
        });
      }
    }

    return ret;
  }

  public getCurrentViewType(): ViewType {
    if (!this.rendCtx) {
      return ViewType.VIEW_TYPE_COLUMNIZED;
    }

    const pub = this.rendCtx.rendition.getPublication();
    let layout = ViewType.VIEW_TYPE_COLUMNIZED;
    if (pub.metadata.rendition && pub.metadata.rendition.layout === 'fixed') {
      layout = ViewType.VIEW_TYPE_FIXED;
    }

    return layout;
  }

  public addIFrameEventListener(
    eventName: string,
    callback: any,
    context: any,
    opts: object,
  ): void {
    if (!this.iframeEventManager) {
      return;
    }
    this.iframeEventManager.addIFrameEventListener(
      eventName,
      callback,
      context,
      opts,
    );
  }

  public updateIFrameEvents(): void {
    const iframes = this.viewRoot.querySelectorAll('iframe');
    this.iframeEventManager.updateIFrameEvents(
      Array.prototype.slice.call(iframes),
    );
  }

  public getRenderedSythenticSpread(): string {
    return '';
  }

  public async updateSettings(settings: any, savedLocation?: any): Promise<void> {
    let spreadMode;
    if (settings.hasOwnProperty('syntheticSpread')) {
      if (this.publication &&
          this.publication.metadata &&
          this.publication.metadata.rendition &&
          this.publication.metadata.rendition.spread === 'none') {
        spreadMode = SpreadMode.FitViewportSingleSpread;
      } else {
        spreadMode = SpreadMode.FitViewportAuto;
        const spreadSetting = settings.syntheticSpread;
        if (spreadSetting === 'auto') {
          spreadMode = SpreadMode.FitViewportAuto;
        } else if (spreadSetting === 'single') {
          spreadMode = SpreadMode.FitViewportSingleSpread;
        } else if (spreadSetting === 'double') {
          spreadMode = SpreadMode.FitViewportDoubleSpread;
        }
      }

      delete settings.syntheticSpread;
    }

    let scrollMode;
    if (settings.hasOwnProperty('scroll')) {
      scrollMode = ScrollMode.None;
      const scrollSetting = settings.scroll;
      if (scrollSetting === 'auto') {
        scrollMode = ScrollMode.None;
      } else if (scrollSetting === 'scroll-continuous') {
        scrollMode = ScrollMode.Publication;
      } else if (scrollSetting === 'scroll-doc') {
        scrollMode = ScrollMode.SpineItem;
      }

      delete settings.scroll;
    }

    const r2Settings = this.vsAdapter.r2ViewSettings(settings);

    if (!this.rendCtx) {
      if (spreadMode) {
        this.spreadMode = spreadMode;
      }

      if (scrollMode) {
        this.scrollMode = scrollMode;
      }

      this.defaultViewsettings.updateSetting(r2Settings);

      getReadiumEventsRelayInstance().triggerSettingApplied();
      return;
    }

    let loc;
    if (savedLocation && savedLocation.hasOwnProperty('idref')) {
      const locIdref = this.hrefFromIdref(savedLocation.idref);
      loc = new Location(savedLocation.contentCFI, 'text/html', locIdref, []);
    } else {
      loc = this.rendCtx.navigator.getCurrentLocation();
    }

    this.rendCtx.rendition.viewport.beginViewUpdate();

    if (spreadMode) {
      this.spreadMode = spreadMode;
      this.rendCtx.rendition.setPageLayout({ spreadMode });
    }

    if (scrollMode) {
      this.updateScrollMode(scrollMode);

      this.rendCtx.rendition.refreshPageLayout();
    }

    this.rendCtx.rendition.updateViewSettings(r2Settings);

    this.rendCtx.rendition.viewport.endViewUpdate();

    if (loc) {
      await this.rendCtx.navigator.gotoLocation(loc);
    }
    getReadiumEventsRelayInstance().triggerSettingApplied();
  }

  public viewerSettings(): any {
    const vs: ViewSettings = new ViewSettings();
    vs.updateSetting(this.defaultViewsettings.getAllSettings());
    if (this.rendCtx) {
      const vsSettings = this.rendCtx.rendition.viewSettings().getAllSettings();
      vs.updateSetting(vsSettings);
    }

    vs.updateSetting([{ name: SettingName.SpreadMode, value: this.spreadMode }]);
    return this.vsAdapter.rsjViewerSettings(vs);
  }

  public getDefaultViewScale(): number {
    return 1;
  }

  public getViewScale(): number {
    if (!this.rendCtx) {
      return 100;
    }

    const itemRang = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
    if (itemRang.length === 0) {
      return this.getDefaultViewScale() * 100;
    }

    return this.rendCtx.rendition.viewport.getViewScale(itemRang[0]) * 100;
  }

  // tslint:disable-next-line:no-empty
  public async setZoom(options: IZoomOption): Promise<void> {
    if (!this.rendCtx || !this.epubContainer) {
      return;
    }
    if (!this.isCurrentViewFixedLayout()) {
      return;
    }

    this.epubContainer.style.overflow = 'auto';

    const loc = this.rendCtx.navigator.getCurrentLocation();

    if (options.style === 'user') {
      const oldScale = this.getViewScale() / 100;
      const bookviewScale = this.rendCtx.rendition.getZoomScale();
      const transformaedBookviewScale =
        (bookviewScale * options.scale) / oldScale;
      this.rendCtx.rendition.setZoom(
        this.rendCtx.rendition.getZoomOption(),
        transformaedBookviewScale,
      );
    } else if (options.style === 'fit-screen') {
      this.viewRoot.style.overflow = 'hidden';
      this.rendCtx.rendition.setZoom(ZoomOptions.FitByPage, 1);
    } else if (options.style === 'fit-width') {
      this.viewRoot.style.overflowX = 'hidden';
      this.viewRoot.style.overflowY = 'auto';
      this.rendCtx.rendition.setZoom(ZoomOptions.FitByWidth, 1);
    }

    if (loc) {
      await this.rendCtx.navigator.gotoLocation(loc);
    }
  }

  public getStartCfi(idref?: string): object | undefined {
    if (!this.rendCtx) {
      return undefined;
    }

    let r1View;
    if (idref === undefined) {
      const spineItemIndices = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
      if (spineItemIndices.length === 0) {
        return undefined;
      }

      const firstVisibleSpineItemIndex = spineItemIndices[0];
      r1View = this.getContentViewFromSpineItemIndex(firstVisibleSpineItemIndex);
    } else {
      r1View = this.getContentViewFromIdref(idref);
    }

    if (!r1View) {
      return undefined;
    }

    return r1View.getStartCfi();
  }

  public getEndCfi(idref?: string): object | undefined {
    if (!this.rendCtx) {
      return undefined;
    }

    let r1View;
    if (idref === undefined) {
      const spineItemIndices = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
      if (spineItemIndices.length === 0) {
        return undefined;
      }

      const lastVisibleSpineItemIndex = spineItemIndices[spineItemIndices.length - 1];
      r1View = this.getContentViewFromSpineItemIndex(lastVisibleSpineItemIndex);
    } else {
      r1View = this.getContentViewFromIdref(idref);
    }

    if (!r1View) {
      return undefined;
    }

    return r1View.getEndCfi();
  }

  public getFirstVisibleCfi(idref?: string): object | undefined {
    if (!this.rendCtx) {
      return undefined;
    }

    if (idref == undefined) {
      const loc = this.rendCtx.navigator.getScreenBegin();
      if (!loc) {
        return undefined;
      }
      idref = this.idrefFromHref(loc.getHref());
      return { idref, contentCFI: loc.getLocation() };
    } else {
      const contentCFI = this.getFirstOrLastCfiOfSpineItem(idref, false);
      if (!contentCFI) {
        return undefined;
      }
      return { idref, contentCFI };
    }
  }

  public getLastVisibleCfi(idref?: string): object | undefined {
    if (!this.rendCtx) {
      return undefined;
    }

    if (idref == undefined) {
      const loc = this.rendCtx.navigator.getScreenEnd();
      if (!loc) {
        return undefined;
      }
      idref = this.idrefFromHref(loc.getHref());
      return { idref, contentCFI: loc.getLocation() };
    } else {
      const contentCFI = this.getFirstOrLastCfiOfSpineItem(idref, true);
      if (!contentCFI) {
        return undefined;
      }
      return { idref, contentCFI };
    }
  }

  public getElements(idref: string, selector: string): any {
    const r1View = this.getContentViewFromIdref(idref);

    return r1View ? r1View.getElements(selector) : undefined;
  }

  public getElementById(idref: string, id: string): any {
    const r1View = this.getContentViewFromIdref(idref);

    return r1View ? r1View.getElementById(id) : undefined;
  }

  public isElementVisible(ele: HTMLElement): boolean {
    if (!this.rendCtx) {
      return false;
    }

    const siIndex = this.findSpineItemIndexFromDocument(ele.ownerDocument);
    if (siIndex < 0) {
      return false;
    }

    const offset = this.rendCtx.rendition.viewport.getOffsetInSpineItemView(siIndex);
    if (offset === undefined) {
      return false;
    }

    const r1View = this.getContentViewFromSpineItemIndex(siIndex);

    return r1View ? r1View.isElementVisible($(ele), offset, 0) : false;
  }

  public getNearestCfiFromElement(ele: HTMLElement): any {
    const r1View = this.getContentViewFromDocument(ele.ownerDocument);

    return r1View ? r1View.getNearestCfiFromElement(ele) : undefined;
  }

  public isVisibleSpineItemElementCfi(): boolean {
    return false;
  }

  public getRangeCfiFromDomRange(range: Range): any {
    const r1View = this.getContentViewFromDocument(
      range.startContainer.ownerDocument,
    );

    return r1View ? r1View.getRangeCfiFromDomRange(range) : undefined;
  }

  public getVisibleElements(selector: string): any[] {
    if (!this.rendCtx) {
      return [];
    }

    const itemRange = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
    const ret: any[] = [];
    if (itemRange.length === 0) {
      return ret;
    }

    for (let i = itemRange[0]; i <= itemRange[1]; i = i + 1) {
      const r1View = this.getContentViewFromSpineItemIndex(i);
      if (r1View) {
        const eles = r1View.getVisibleElements(selector, true);
        ret.push(...eles);
      }
    }

    return ret;
  }

  public bookmarkCurrentPage(): string | null {
    const bookmark = this.getFirstVisibleCfi();

    return bookmark ? JSON.stringify(bookmark) : null;
  }

  public getPaginationInfo(): any {
    const info = this.getPaginationInfoInternal();

    return info.paginationInfo;
  }

  public openPageRight(): void {
    if (!this.rendCtx) {
      return;
    }
    this.rendCtx.navigator.nextScreen();
  }

  public openPageLeft(): void {
    if (!this.rendCtx) {
      return;
    }
    this.rendCtx.navigator.previousScreen();
  }

  public async openSpineItemElementCfi(
    idref: string,
    elementCfi: string,
    initiator: any,
  ): Promise<void> {
    if (!this.rendCtx) {
      return;
    }
    const href = this.hrefFromIdref(idref);
    return this.rendCtx.navigator.gotoLocation(new Location(elementCfi, 'text/html', href, []));
  }

  public async openSpineItemPage(
    idref: string,
    pageIndex?: number,
    initiator?: any,
  ): Promise<void> {
    if (!this.rendCtx) {
      return;
    }
    if (pageIndex !== undefined) {
      console.warn('openSpineItemPage: page index is ignored');
    }
    const href = this.hrefFromIdref(idref);
    return this.rendCtx.navigator.gotoLocation(new Location('', 'text/html', href, []));
  }

  public async openSpineItemElementId(idref: string, hashFrag: string): Promise<void> {
    if (!this.rendCtx) {
      return;
    }
    const href = this.hrefFromIdref(idref);
    return this.rendCtx.navigator.gotoAnchorLocation(href, hashFrag);
  }

  public openContentUrl(contentUrl: string, sourceFileUrl?: string): void {
    if (!this.rendCtx) {
      return;
    }

    const combinedPath = Helpers.ResolveContentRef(
      contentUrl,
      sourceFileUrl,
    );

    const hashIndex = combinedPath.indexOf('#');
    let hrefPart;
    let elementId;
    if (hashIndex >= 0) {
      hrefPart = combinedPath.substr(0, hashIndex);
      elementId = combinedPath.substr(hashIndex + 1);
    } else {
      hrefPart = combinedPath;
      elementId = '';
    }

    this.rendCtx.navigator.gotoAnchorLocation(hrefPart, elementId);
  }

  public resolveContentUrl(
    contentRefUrl: string,
    sourceFileHref: string,
  ): object | boolean {
    const combinedPath = Helpers.ResolveContentRef(
      contentRefUrl,
      sourceFileHref,
    );

    const hashIndex = combinedPath.indexOf('#');
    let hrefPart;
    let elementId;
    if (hashIndex >= 0) {
      hrefPart = combinedPath.substr(0, hashIndex);
      elementId = combinedPath.substr(hashIndex + 1);
    } else {
      hrefPart = combinedPath;
      elementId = undefined;
    }

    let spineItem = this.rsjPackage.spine.getItemByHref(hrefPart);
    if (!spineItem) {
      console.warn(`spineItem ${hrefPart} not found`);
      // sometimes that happens because spine item's URI gets encoded,
      // yet it's compared with raw strings by `getItemByHref()` -
      // so we try to search with decoded link as well
      const decodedHrefPart = decodeURIComponent(hrefPart);
      spineItem = this.rsjPackage.spine.getItemByHref(decodedHrefPart);
      if (!spineItem) {
        console.warn(`decoded spineItem ${decodedHrefPart} missing as well`);

        return false;
      }
    }

    return { elementId, href: hrefPart, idref: spineItem.idref };
  }

  public pauseMediaOverlay(): void {
    return;
  }

  public resetMediaOverlay(): void {
    return;
  }

  public getDomRangeFromRangeCfi(rangeCfi: any, rangeCfi2: any, inclusive: boolean): any {
    if (rangeCfi2 && rangeCfi.idref !== rangeCfi2.idref) {
      // tslint:disable-next-line:max-line-length
      console.error('getDomRangeFromRangeCfi: both CFIs must be scoped under the same spineitem idref');
      return undefined;
    }

    const contentView = this.getContentViewFromIdref(rangeCfi.idref);
    if (!contentView) {
      return undefined;
    }

    return contentView.getDomRangeFromRangeCfi(rangeCfi, rangeCfi2, inclusive);
  }

  private getFirstOrLastCfiOfSpineItem(idref: string, lastCfi: boolean): string {
    if (!this.rendCtx) {
      return '';
    }

    const spineItemView = this.getContentViewFromIdref(idref);
    if (!spineItemView) {
      return '';
    }

    const siIndex = this.publication.findSpineItemIndexByHref(this.hrefFromIdref(idref));
    const offset = this.rendCtx.rendition.viewport.getOffsetInSpineItemView(siIndex) || 0;

    return spineItemView.getCfi(offset, 0, lastCfi);
  }

  private initDefaultViewSettings(): void {
    const columnGapSetting = { name: SettingName.ColumnGap, value: 20 };
    const maxColumnWidthSetting = { name: SettingName.MaxColumnWidth, value: 700 };
    this.defaultViewsettings.updateSetting([columnGapSetting, maxColumnWidthSetting]);
  }

  private findSpineItemIndexFromDocument(doc: Document | null): number {
    let spineItemIndex = -1;
    const iframes = this.viewRoot.querySelectorAll('iframe');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < iframes.length; i = i + 1) {
      const iframe = iframes[i];
      if (doc === iframe.contentDocument) {
        const viewDiv = this.getParentElement(iframe, 3);
        spineItemIndex = this.getSpineItemIndexFromId(viewDiv);
      }
    }

    return spineItemIndex;
  }

  private getParentElement(node: HTMLElement, level: number): HTMLElement {
    let n = node;
    let count = 1;
    while (n.parentElement && count <= level) {
      n = n.parentElement;
      count = count + 1;
    }

    return n;
  }

  private getSpineItemIndexFromId(node: HTMLElement): number {
    const components = node.id.split('-');

    return parseInt(components[components.length - 1], 10);
  }

  private getContentViewFromSpineItemIndex(
    siIndex: number,
  ): R1ContentView | undefined {
    if (!this.rendCtx) {
      return undefined;
    }

    const siView = this.rendCtx.rendition.viewport.getSpineItemView(siIndex);
    if (!siView) {
      return undefined;
    }

    return <R1ContentView>siView.getContentView();
  }

  private getContentViewFromIdref(idref: string): R1ContentView | undefined {
    if (!this.rendCtx) {
      return undefined;
    }

    const href = this.hrefFromIdref(idref);

    const siIndex = this.rendCtx.rendition
      .getPublication()
      .findSpineItemIndexByHref(href);
    if (siIndex < 0) {
      return undefined;
    }

    return this.getContentViewFromSpineItemIndex(siIndex);
  }

  private getContentViewFromDocument(
    doc: Document | null,
  ): R1ContentView | undefined {
    const siIndex = this.findSpineItemIndexFromDocument(doc);
    if (siIndex < 0) {
      return undefined;
    }

    return this.getContentViewFromSpineItemIndex(siIndex);
  }

  public reset(): void {
    if (!this.rendCtx) {
      return;
    }

    this.rendCtx.rendition.reset();
  }

  private updateScrollMode(scroll: ScrollMode): void {
    if (!this.rendCtx) {
      return;
    }

    if (this.scrollMode === scroll) {
      return;
    }

    const isVertical = scroll !== ScrollMode.None;

    this.rendCtx.rendition.reset();
    this.rendCtx.rendition.setViewAsVertical(isVertical);

    let viewportSize = isVertical ? this.viewportHeight : this.viewportWidth;
    const viewportSize2 = isVertical ? this.viewportWidth : this.viewportHeight;

    // AXNG-116: round to even number to avoid fractional column width
    if (!isVertical && viewportSize % 2 !== 0) {
      viewportSize -= 1;
    }

    this.rendCtx.rendition.viewport.setViewportSize(viewportSize, viewportSize2);
    this.rendCtx.rendition.viewport.setPrefetchSize(viewportSize * 0.1);
    this.rendCtx.rendition.viewport.setScrollMode(scroll);

    this.scrollMode = scroll;
  }

  private replaceCanGoLeftRight(paginationInfo: any): void {
    paginationInfo.canGoLeft = () => {
      if (!this.rendCtx) {
        return false;
      }
      return !this.rendCtx.navigator.isFirstScreen();
    };
    paginationInfo.canGoRight = () => {
      if (!this.rendCtx) {
        return false;
      }
      return !this.rendCtx.navigator.isLastScreen();
    };
  }

  private hrefFromIdref(idref: string): string {
    const href = this.idrefToHrefMap.get(idref);
    return href ? href : idref;
  }

  private idrefFromHref(href: string): string {
    const idref = this.hrefToIdrefMap.get(href);
    return idref ? idref : href;
  }

  private getPaginationInfoInternal(): any {
    const paginationInfo: any = {};
    let openPages: any[] = [];
    let spineItem: any;
    if (this.rendCtx) {
      const itemRange = this.rendCtx.rendition.viewport.visibleSpineItemIndexRange();
      for (let i = itemRange[0]; i <= itemRange[1]; i = i + 1) {
        const r1View = this.getContentViewFromSpineItemIndex(i);
        if (!spineItem) {
          spineItem = this.rsjPackage.spine[i];
        }
        if (r1View) {
          const pageInfo = (<any>r1View.getPaginationInfo()).paginationInfo;
          openPages = [...openPages, ...pageInfo.openPages];
        }
      }
    }

    this.replaceCanGoLeftRight(paginationInfo);
    paginationInfo.openPages = openPages;

    return {
      paginationInfo,
      spineItem,
      initiator: this,
      elementId: undefined,
    };
  }
}

class ViewportResizer {
  private viewRoot: HTMLElement;
  private rendition: Rendition;
  private navigator: Navigator;

  private location: Location | null | undefined;

  public constructor(viewRoot: HTMLElement, rendi: Rendition, nav: Navigator) {
    this.viewRoot = viewRoot;
    this.rendition = rendi;
    this.navigator = nav;

    this.registerResizeHandler();
  }

  private registerResizeHandler(): void {
    const lazyResize = Helpers.extendedThrottle(
      this.handleViewportResizeStart.bind(this),
      this.handleViewportResizeTick.bind(this),
      this.handleViewportResizeEnd.bind(this),
      250,
      1000,
      self,
    );

    var isMobile = navigator.userAgent.match(/(iPad|iPhone|iPod|Android|Mobile)/g) ? true : false;
    if (isMobile) {
      $(window).on('orientationchange.ReadiumSDK.readerView', lazyResize);
    } else {
      $(window).on('resize.ReadiumSDK.readerView', lazyResize);
    }
  }

  private handleViewportResizeStart(): void {
    this.location = this.navigator.getCurrentLocation();
  }

  private handleViewportResizeTick(): void {
    // this.resize();
  }

  private async handleViewportResizeEnd(): Promise<void> {
    this.resize();

    if (this.location) {
      await this.rendition.viewport.renderAtLocation(this.location);
    }
  }

  private resize(): void {
    let newWidth = this.viewRoot.clientWidth;
    const newHeight = this.viewRoot.clientHeight;

    // AXNG-116: round to even number to avoid fractional column width
    if (newWidth % 2 !== 0) {
      newWidth -= 1;
    }

    this.rendition.viewport.setViewportSize(newWidth, newHeight);
    this.rendition.refreshPageLayout();
  }
}

interface IframeEventHandler {
  callback: any;
  context: any;
  opts: any;
}

class IframeEventManager {
  // private iframeEvents: Map<string, IframeEventHandler[]> = new Map();
  private eventListeners: Map<string, any[]> = new Map();
  private internalLinkSupport: any;
  private viewerSetttings: () => any;
  
  public constructor() {
    getReadiumEventsRelayInstance().on(
      RsjGlobals.Events.CONTENT_DOCUMENT_LOADED,
      ($iframe: any, spineItem: any) => {
        this.updateIframeEventsInternal($iframe[0]);
        if (this.internalLinkSupport) {
          this.internalLinkSupport.processLinkElements($iframe, spineItem, this.viewerSetttings().enableInternalLinks);
        }
      },
    );
  }

  public setViewerSetttings(callback: () => any): void {
    this.viewerSetttings = callback; 
  }
  
  public setInternalLinkSupport(linkSupport: any): void {
    this.internalLinkSupport = linkSupport;
  }

  public addIFrameEventListener(
    spaceSeparatedEventNames: string,
    callback: any,
    context: any,
    opts: any,
  ): void {
    let useCapture = false;
    if (opts && opts.useCapture) {
      useCapture = true;
    }

    var eventNames = spaceSeparatedEventNames.split(' ');
    for (var i = 0; i < eventNames.length; i++) {
        var eventName = eventNames[i];
        if (this.eventListeners.get(eventName) == undefined) {
            this.eventListeners.set(eventName, []);
        }
        this.eventListeners.get(eventName)!.push({callback: callback, context: context, useCapture: useCapture});
    }
  }

  public updateIFrameEvents(iframes: HTMLIFrameElement[]): void {
    iframes.forEach(iframe => this.updateIframeEventsInternal(iframe));
  }

  private updateIframeEventsInternal(iframe: HTMLIFrameElement): void {
    // this.iframeEvents.forEach((eventHandlers, eventName) => {
    //   eventHandlers.forEach((handler: IframeEventHandler) => {
    //     this.bindIframeEvent(iframe, eventName, handler);
    //   });
    // });
    this.eventListeners.forEach((eventHandlers, eventName) => {
      for (var i = 0, count = eventHandlers.length; i < count; i++) {
        iframe.contentWindow!.removeEventListener(eventName, eventHandlers[i].callback, eventHandlers[i].useCapture);
        iframe.contentWindow!.addEventListener(eventName, eventHandlers[i].callback, eventHandlers[i].useCapture);
        //$(iframe.contentWindow).on(key, value[i].callback, value[i].context);
    }
    });
  }

  // private bindIframeEvent(
  //   iframe: HTMLIFrameElement,
  //   eventName: string,
  //   handler: IframeEventHandler,
  // ): void {
  //   if (!iframe.contentWindow) {
  //     return;
  //   }

  //   if (handler.opts) {
  //     if (handler.opts.onWindow) {
  //       if (handler.opts.jqueryEvent) {
  //         this.addJqueryEvent($(iframe.contentWindow), eventName, handler);
  //       } else {
  //         this.addNativeEvent(iframe.contentWindow, eventName, handler);
  //       }
  //     } else if (handler.opts.onDocument) {
  //       if (handler.opts.jqueryEvent) {
  //         this.addJqueryEvent(
  //           $(iframe.contentWindow.document),
  //           eventName,
  //           handler,
  //         );
  //       } else {
  //         this.addNativeEvent(
  //           iframe.contentWindow.document,
  //           eventName,
  //           handler,
  //         );
  //       }
  //     }
  //   } else {
  //     this.addJqueryEvent($(iframe.contentWindow), eventName, handler);
  //   }
  // }

  // private addNativeEvent(
  //   target: Window | Document,
  //   eventName: string,
  //   handler: IframeEventHandler,
  // ): void {
  //   target.addEventListener(eventName, handler.callback, handler.context);
  // }

  // private addJqueryEvent(
  //   target: any,
  //   eventName: string,
  //   handler: IframeEventHandler,
  // ): void {
  //   target.on(eventName, handler.callback, handler.context);
  // }
}
