import { ReadiumReaderViewAdapter } from './reader-view-adapter';
import {Fonts} from './fonts';

import { Globals as RsjGlobals, Plugins } from '@axisnow/readium-shared-js';
import { Publication } from '@readium/navigator-web';

/* tslint:disable:no-any */

export class Readium {
  public reader: ReadiumReaderViewAdapter;

  private root: HTMLElement;
  private epubContainer?: HTMLElement;

  public constructor(readiumOptions: any, readerOptions: any) {
    if (readerOptions.el && readerOptions.el.length !== 0) {
      this.epubContainer = document.querySelector(readerOptions.el);
    }

    this.root = document.createElement('div');
    this.root.setAttribute('id', 'book-frame');
    this.root.style.overflow = 'hidden';
    this.root.style.margin = 'auto';

    if (this.epubContainer) {
      this.epubContainer.appendChild(this.root);
    }

    this.reader = new ReadiumReaderViewAdapter(this.root, this.epubContainer);

    if (readerOptions.fonts) {
      Fonts.setFonts(readerOptions.fonts);
    }

    try {
      Plugins.initialize(this.reader);
    } catch (ex) {
      console.error('Plugins failed to initialize:', ex);
    }

    setTimeout(() => {
      this.reader.emit(RsjGlobals.Events.PLUGINS_LOADED, this.reader);
    });
  }

  public async openPackageDocument(bookUrl: string,
                                   callback: any,
                                   initialCFI: any): Promise<void> {
    if (!this.root) {
      return Promise.resolve();
    }

    await this.reader.openPublicationFromURL(bookUrl);

    const packageDocument = this.reader.getReadiumPackageDocument();
    const options = {
      metadata: {},
    };
    callback(packageDocument, options);

    this.reader.openPage();
  }
}
