import { R1ContentView } from './r1-content-view';

import { CancellationToken } from '@readium/navigator-web';
import {
  Globals as Readium,
  ReflowableView,
} from '@axisnow/readium-shared-js';
import { getReadiumEventsRelayInstance } from '../readium-events-relay';

 // tslint:disable:no-any

export class R1MultiPageContentView extends R1ContentView {
  protected loadSpineItemContentViewImpl(params: any, reader: any,
                                         rsjViewerSettings: any,
                                         token?: CancellationToken): Promise<void> {
    this.contentViewImpl = new ReflowableView(params, reader);

    getReadiumEventsRelayInstance().registerEvents(this.contentViewImpl);

    this.contentViewImpl.on(
      Readium.Events.CONTENT_DOCUMENT_LOAD_START,
      ($iframe: any, spineItem: any) => {
        this.$iframe = $iframe;
        this.rjsSpineItem = spineItem;
      },
    );

    this.contentViewImpl.render();

    this.contentViewImpl.setViewSettings(rsjViewerSettings, true);

    this.contentViewImpl.openPage({ spineItem: this.rsjSpine.items[this.spineItemIndex] });

    return this.paginationChangedPromise(token);
  }

}
