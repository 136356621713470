import { PackageDocument } from '../package-document';

import {
  IContentView,
  IContentViewFactory,
  IFrameLoader,
  Publication,
} from '@readium/navigator-web';
import { R1MultiPageContentView } from './r1-multi-page-content-view';
import { R1SinglePageContentView } from './r1-single-page-content-view';

// tslint:disable-next-line:no-implicit-dependencies
import {
  Package as ReadiumPackage,
  StyleCollection,
  ViewerSettings,
} from '@axisnow/readium-shared-js';

// tslint:disable:no-any

export class R1ContentViewFactory implements IContentViewFactory {
  private rsjPackage: any;
  private rsjPackageDoc: PackageDocument;
  private rsjViewSettings: any = new ViewerSettings({
    syntheticSpread: 'single',
  });

  private rsjBookStyle: any = new StyleCollection();

  private iframeLoader: IFrameLoader;

  public constructor(pub: Publication, iframeLoader?: IFrameLoader, packageDoc?: PackageDocument) {
    this.iframeLoader = iframeLoader || new IFrameLoader(pub.getBaseURI());

    this.rsjPackageDoc = packageDoc ? packageDoc : new PackageDocument(pub);
    this.rsjPackage = new ReadiumPackage({
      ...this.rsjPackageDoc.getSharedJsPackageData(),
    });
    this.rsjPackage.spine.handleLinear(true);
  }

  public createContentView(
    isFixedLayout: boolean,
    isVertical: boolean,
  ): IContentView {
    if (isFixedLayout || isVertical) {
      return new R1SinglePageContentView(
        this.iframeLoader,
        this.rsjPackage.spine,
        isFixedLayout,
        this.rsjBookStyle,
      );
    }

    return new R1MultiPageContentView(this.iframeLoader, this.rsjPackage.spine, this.rsjBookStyle);
  }

  public viewSettings(): any {
    return this.rsjViewSettings;
  }

  public getIframeLoader(): IFrameLoader {
    return this.iframeLoader;
  }

  public getReadiumPackageDocument(): PackageDocument {
    return this.rsjPackageDoc;
  }

  public getReadiumPackage(): any {
    return this.rsjPackage;
  }

  public getBookStyle(): any {
    return this.rsjBookStyle;
  }
}
